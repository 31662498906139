body {
	background: #f9f9f9;
}

a {
	color: #da291c;
}

header.rc-layout {


	.rc-layout-inner {

		.avaya-main-logo {
			display: block;
			width: 316px;
			height: 70px;
			background: url("../images/avaya-main-logo.png") no-repeat;
		}

		@include respond-to(smallDesktop){
			padding: 0;

			.rc-main-logo {
				margin: 2px 0;
			}
		}
	}
}


.dashboard-title-wrapper {

	.dashboard-title {

		.serv-label1 {
			color: #da291c;
			font-weight: 600;
			border-bottom: 2px solid #da291c;
		}

	}

}

.dashboard-wrapper .services-tab .events-block > div .big-block > input[type=checkbox]:checked ~ label,
.dashboard-wrapper .services-tab .events-block > div .single-block > input[type=checkbox]:checked ~ label {
	color: #da291c;
}

footer.rc-layout {
	background: #fff;

	.rc-layout-inner {

		p {
			text-align: left;
			margin-bottom: 20px;
			color: #333;
			line-height: 1.4;
		}
	}
}

.dashboard-wrapper {
	.services-tab {

		.warning-block {

			.warning-description {

				.right-description-block {

					.right-title {
						background: #e2e2e2;
					}
				}
			}

			.warning-label.reveal {
				background: #e2e2e2;

				& + .warning-description {
					background: #e2e2e2;
				}
			}
		}
	}
}