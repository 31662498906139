// =============================================================================
// RC Header
// =============================================================================


header.rc-layout {

	padding: $rc-layout-padding $rc-side-padding;
	position: relative;
	background: $rc-plate-bg-color;


	@include respond-to(smallScreenG) {
		padding: 30px 0 10px;
	}

	.rc-layout-inner {

		& > * {
			width: 33%;

			@include respond-to(smallScreenG) {
				width: 100%;
			}

		}

		.header-side {
			text-align: right;

			@include respond-to(smallScreenG) {
				position: absolute;
				right: 16px;
				margin: 12px auto 0;

			}
		}

		.rc-main-logo {

			display: block;
			width: 154px;
			height: 25px;
			background: url(../images/rc-main-logo.svg) 0 0 no-repeat;
			background-size: contain;

			@include respond-to(largePhone) {
				margin: 19px auto 0;
			}

		}

		.sales-or-chat {

			color: #9f9f9f;
			font-size: 1.4rem;
			line-height: 22px;

			.rc-sales-number {
				color: #2e2e2e;
			}

			.rc-sales-chat {
				color: #2e2e2e;
			}

			@include respond-to(largePhone) {
				display: block;
				position: absolute;
				margin: -30px 0 0 0;
				white-space: nowrap;
				left: 0;
				right: 0;
				height: 30px;
				line-height: 30px;
				background-color: #005586;


				.rc-sales-number {
					color: #FFFFFF;
				}

				.rc-sales-chat,
				.rc-sales-or {
					display: none;
				}

			}
		}

	}

}
