// =============================================================================
// RC Colors
// =============================================================================


$rc-main-bg-color: 						#f0f8fc;
$rc-main-border-color:					#E4E5E6;
$rc-darkplatebg-color: 	                #0684bd;
$rc-sub-text-color: 					#9f9f9f;

$rc-footer-bg-color:                 	#243956;
$rc-plate-bg-color: 					#fff;
$rc-main-text-color: 					#2e2e2e;
$rc-frogger-text-color: 				#999;
$rc-frogger-head-bg:					#404040;
$rc-orange-text:						#f5a623;
$rc-green-text:							#5bc558;
$rc-placeholder-text-color: 			#aaa;
$rc-corporate-orange:					#ff9900;
$rc-notavailable:						#ff2e52;
$svg-color-frogger:                     #404040;

$rc-primary-btn-bg:						#0684BD;
$rc-primary-btn-bg-hover:				#0573AE;
$rc-primary-btn-color:					#fff;
$rc-orange-btn-bg:                 		#FF8800;
$rc-orange-btn-bg-hover:              	#ff7400;
$rc-pro-primary-btn-bg:                 #fe932d;
$rc-pro-primary-bg-hover:               #ff8800;

$rc-primary-link-color:					#0073ae;
$rc-footer-text-color:					#818181;

$total-color: 							#005684;
$shadow-color: 							#000;
$row-background:						#F8F8F8;
$main-fnt-color: 						#333;
$border-darken-color: 					#7e7f80;

$rc-salesnumber-color:					$rc-primary-btn-bg;

$rc-tooltip-bg-color: 					#fafafa;
$rc-tooltip-border:						#d6d6d6;

$rc-focused-input-border-color: 		$rc-primary-btn-bg; /*#0099cc;*/
$rc-input-border-color: 				$rc-main-border-color;
$rc-input-bg-color:						$rc-plate-bg-color;
$rc-input-text-color: 					$rc-main-text-color; //$rc-sub-text-color;

$rc-inport-links-color:					$rc-focused-input-border-color;

$rc-fieldset-border-color:				#ebebeb;
$rc-fieldset-bg-color:					$rc-input-bg-color;

$rc-incident-normal-color:              #74B78C;
$rc-incident-minor-color:               #D5B537;
$rc-incident-high-color:                #E7603E;

$rc-input-radius:						3px;
$rc-main-border-radius:					3px;
$rc-default-text-size:					12;

$rc-font-semibold:						"Proxima Nova Semibold", sans-serif;
$rc-font-normal:						"Proxima Nova", sans-serif;
$rc-font-bold:							"Proxima Nova Bold", sans-serif;
$rc-font-light:							"Proxima Nova Light", sans-serif;

$rc-font-display-extralight:			"Ringcentral Display Extralight", sans-serif;
$rc-font-display-normal:				"Ringcentral Display", sans-serif;
$rc-font-display-bold:					"Ringcentral Display Bold", sans-serif;
$rc-font-display-medium:				"Ringcentral Display Medium", sans-serif;

$rc-font-sans-normal:					"RingCentralSans", sans-serif;
$rc-font-sans-bold:						"RingCentralSans Bold", sans-serif;
$rc-font-sans-medium:					"RingCentralSans Medium", sans-serif;