// =============================================================================
// RC Footer
// =============================================================================

footer.rc-layout {

	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	//padding: $rc-layout-padding $rc-side-padding;
	padding: 12px 19px;
	background: $rc-footer-bg-color;

	.rc-layout-inner {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		p,
		ul li a {
			font-family: $rc-font-semibold;
			font-size: 1.2rem;
			color: #E4E5E6;
			margin-bottom: 0;
		}

		ul {
			margin: 10px 0;
			height: auto;

			li {
				margin: 0;
				a:hover {
					color: #fff;
				}
			}
		}

		p {
			text-align: right;
			line-height: .9;
		}

		.footer-social {
			li {
				margin: 0 14px 0 0;

				a {
					display: block;
					height: 20px;
					width: 20px;
					//background-size: contain;
					position: relative;
				}

				.facebook {
					background: url(../images/fb.svg) no-repeat center center;
				}
				.twitter {
					background: url(../images/twitter.svg) no-repeat center center;
					top: 3px;
				}
				.linkedin {
					background: url(../images/linkedin.svg) no-repeat center center;
				}
				.youtube {
					top: 0.5px;
					background: url(../images/youtube-white.svg) no-repeat center center;
				}
				.instagram {
					background: url(../images/instagram-white.svg) no-repeat center center;
				}
			}

		}

		.footer-links {
			li {
				padding: 0 10px;
				border-left: 1px solid #E4E5E6;
				line-height: .9;
				&:first-child {
					padding-left: 0;
					border: none;
				}
			}
		}

		.partners-links {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;

			li {
				margin: 0 0 0 19px;

				a {
					display: block;
					background-image: url(../images/logoSpriteFooter.jpg);
					position: relative;
					background-size: 202px;

					@include image2x() {
						background-image: url(../images/logoSpriteFooterRetina.png);
					}
				}

				.accredited-bbb {
					width: 62px;
					height: 23px;
					background-position: -3px -14px;
				}

				.skyhight {
					height: 34px;
					width: 34px;
					background-position: -85px -9px;
					border-radius: 50px;
				}

				.trustwave {
					height: 29px;
					width: 59px;
					background-position: -139px -13px;
				}

			}

			@include respond-to(smallScreenG) {
				justify-content: flex-end;
				margin-top: 11px;
			}

		}

		@include respond-to(smallScreenG) {
			flex-wrap: wrap;
			padding: 0;

			div {
				width: 50%;

				&:nth-child(2) {
					order: 3;
				}

				&:nth-child(3) {
					order: 4;
				}
			}

			p {
				text-align: right;
			}

		}

	}
}
