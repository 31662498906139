
$rc-btn-pad:				0 24px;
$rc-btn-minwidth:			150px;
$rc-btn-small:			    99px;
$rc-btn-big:				300px;
$rc-btn-middle:				202px;
$rc-btn-radius:				$rc-main-border-radius;


// =============================================================================
// General Button
// =============================================================================

.buttons {
	text-align: center;
}

button,
input[type="button"],
input[type="submit"],
input[type="reset"] {

	display: inline-block;
	background: $rc-orange-btn-bg;
	border: 1px solid $rc-orange-btn-bg;
	line-height: 2.4;
	color: $rc-primary-btn-color;
	padding: $rc-btn-pad;
	border-radius: $rc-btn-radius;
	min-width: $rc-btn-minwidth;
	font-family: $rc-font-normal;
	font-weight: 400;

	@include font-size(16);
	@include rc-ui-transition();

	&:hover {
		background: $rc-orange-btn-bg-hover;
		border: 1px solid $rc-orange-btn-bg-hover;
	}
	&:focus {
		outline: transparent;
		box-shadow: 0 0 4px 3px #f7d6b0;
	}

	&:disabled,
	&.disabled {
		opacity: 0.6;
	}

	&.big {
		min-width: $rc-btn-big;
	}

	&.middle {
		width: $rc-btn-middle;
	}

	//reverse
	&.small {
		min-width: $rc-btn-small;
		width: $rc-btn-small;
		padding: 0;
	}

	&.transparent-btn {
		background: none;
		color: #2f2f2f;
		border: thin solid #d8d8d8;

		&:hover {
			background: #0576aa;
			color: #ffffff;
			border: thin solid #0684BD;
		}
		&:focus {
			box-shadow: 0 0 4px 3px #a2cdf9;
		}
	}

	&.blue {
		background: $rc-primary-btn-bg;
		border: thin solid $rc-primary-btn-bg;
		margin: 10px auto;

		&:hover {
			background: $rc-primary-btn-bg-hover;
		}
		&:focus {
			box-shadow: 0 0 4px 3px #a2cdf9;
		}
	}

	&.white {
		background: #F3F5F6;
		border: thin solid #F3F5F6;
		color: #2f2f2f;

		&:hover {
			background: #ffffff;
		}
		&:focus {
			box-shadow: 0 0 4px 3px #f1f0f0;
		}
	}

}

.save-btn {
	background: #f80;
	border-radius: 4px;
	color: $rc-plate-bg-color;
	padding: 10px 5px;
	min-width: 48%;
	text-align: center;

	&:nth-of-type(2) {
		margin-left: 10px;
	}

	&:hover {
		background: darken(#f80, 3%);
	}

	&.free-trial,
	&.view-demo {
		background: $rc-plate-bg-color;
		color: $main-fnt-color;

		&:hover {
			background: darken($rc-plate-bg-color, 3%);
		}
	}
}

.share-btn {
	@extend .save-btn;
	background: $rc-primary-btn-bg;
	color: $rc-plate-bg-color;
	font-size: 1.8rem;
	padding: 7px 14px;
	z-index: 100;

	&:hover {
		background: darken($rc-primary-btn-bg, 3%);
	}
}

