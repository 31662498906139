// =============================================================================
// RC Fonts
// =============================================================================

//@include font-face('RobotoRegular', '../fonts/roboto/RobotoRegular');
//@include font-face('RobotoLight', '../fonts/roboto/RobotoLight');

@include font-face('Proxima Nova', '../fonts/proximaNova/ProximaNova');
@include font-face('Proxima Nova Light', '../fonts/proximaNova/ProximaNovaLight');
@include font-face('Proxima Nova Bold', '../fonts/proximaNova/ProximaNovaBold');
@include font-face('Proxima Nova Semibold', '../fonts/proximaNova/ProximaNovaSemibold');
@include font-face('Proxima Nova Medium', '../fonts/proximaNova/ProximaNovaMedium');

@include font-face('Ringcentral Display', '../fonts/ringcentralDisplay/RingCentralDisplay-Regular');
@include font-face('Ringcentral Display Extralight', '../fonts/ringcentralDisplay/RingCentralDisplay-ExtraLight');
@include font-face('Ringcentral Display Bold', '../fonts/ringcentralDisplay/RingCentralDisplay-Bold');
@include font-face('Ringcentral Display Medium', '../fonts/ringcentralDisplay/RingCentralDisplay-Medium');

@include font-face('RingCentralSans', '../fonts/ringcentralSans/RingCentralSans-Regular');
@include font-face('RingCentralSans Bold', '../fonts/ringcentralSans/RingCentralSans-Bold');
@include font-face('RingCentralSans Medium', '../fonts/ringcentralSans/RingCentralSans-Medium');

@include font-face('Inter', '../fonts/inter/InterRegular');
@include font-face('Inter Medium', '../fonts/inter/InterMedium');
@include font-face('Inter Semibold', '../fonts/inter/InterSemiBold');

@include font-face('BTCurve-Regular', '../fonts/BTCurve/BTCurve-Regular');
@include font-face('BTCurve-Bold', '../fonts/BTCurve/BTCurve-Bold');
@include font-face('BTCurve-BoldItalic', '../fonts/BTCurve/BTCurve-BoldItalic');
@include font-face('BTCurve-Italic', '../fonts/BTCurve/BTCurve-Italic');
@include font-face('BTCurve-Headline', '../fonts/BTCurve/BTCurve-Headline');
@include font-face('BTCurve-HeadlineItalic', '../fonts/BTCurve/BTCurve-HeadlineItalic');

html {
	font-size: 62.5%;
}

body {

	@include font-antialiasing();
	@include font-size();

	font-family: $rc-font-normal;
	font-weight: 400;
	color: #333;
	letter-spacing: normal;

}

/*
:selection {
	text-shadow: 0 0 0 rgba(0,0,0,0);
}*/

a {
	color: $rc-primary-link-color;
	text-decoration: none;
	&:hover {
		color: $rc-primary-btn-bg-hover;
	}
}

hr {
	height: 1px;
	border: none;
	font-size: 0;
	background-color: #E4E5E6;
	margin: 7px 0;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 300;
	//letter-spacing: 0.5px;
}

h1 {
	font-family: $rc-font-light;
	color: #2F2F2F;
	margin: 1rem $rc-mobile-padding;
	font-size: 3.6rem;
	line-height: 4.2rem;
	@include respond-to(smallDesktop) {
		margin-bottom: .2rem;
	}
}

h2 {
	font-family: $rc-font-light;
	font-size: 1.8rem;
	line-height: 2.2rem;
	margin: 11px auto 22px;
	color: #2f2f2f;
	@include respond-to(smallDesktop) {
		margin-top: 0;
	}
}

h3 {
	@include font-size(18);
	margin: .4em 0;
	color: #2F2F2F;
	font-weight: 700;
	.num {
		width: 34px;
		height: 34px;
		border-radius: 34px;
		background-color: #F0F8FC;
		display: inline-block;
		text-align: center;
		font-size: 1.6rem;
		line-height: 34px;
		margin: 0 15px 0 0;
	}
}

h4 {
  @include font-size(16);
  margin: .4em 0;
  //color: #9F9F9F;
  color: #333;
  font-weight: 400;
}

/*strong {
  color: $rc-main-text-color;
}*/

.text-right {
	text-align: right;
}

sup {
	font-size: 65%;
}

input,
label {
	@include font-size(16);
	font-weight: 400;
}

p {

  margin: 0 0 1em 0;

  b {
	font-weight: bold;
  }

}

b {
  font-weight: bold;
}

.light {
  color: #9f9f9f;
}

.orange {
  color: $rc-orange-text;
  b {
	color: $rc-orange-text !important;
	font-weight: bold !important;
  }
}

.italic {
  font-style: italic;
}

.text-blue {
  color: $rc-primary-link-color;
}

.text-center {
  text-align: center;
}

.grey {
  color: #999;
}

.red {
  color: red !important;
}

.green {
  color: green !important;
}

.error {
  color: red !important;
  text-align: left;
  display: none;
}

.agree-error {
	color: red !important;
	margin: 10px 0 0 0;
	font-size: 1.2rem;
}


.streamline-error {
	border-radius: $rc-main-border-radius;
	background: #FFF2F2;
	margin: 10px 0;
	padding: 10px 12px;

	p {
		font-size: 1.2rem;
		margin: 0;
	}
	.flash {
		font-size: 1.3rem;
		font-weight: 600;
		color: #EF4B24;
		line-height: 110%;
	}
}

.dynamicNumber {
	color: $rc-salesnumber-color;
}

.or {

	padding: 24px 0 0 0;
	max-width: 350px;
	margin: 0 auto;

	@include respond-to(smartphone) {
		width: 100%;
	}

	span {
		display: inline-block;
		padding: 8px;
		background: $rc-main-bg-color;
		transform: translateY(-50%);
		text-transform: uppercase;
		color: #d8d8d8;
		margin: -1px 0 0 0;
		font-size: 1.2rem;
	}

	&:before {
		display: block;
		content: '';
		font-size: 0;
		height: 0;
		border-top: 1px solid #ccc;
	}

}


a.user-import-link {
  span {
	line-height: 37px;
	@include font-size(14);
  }
}

footer.rc-layout {

  color: $rc-footer-text-color;
  line-height: 1.5;
  @include font-size(11);

  p {
	margin: 0 0 3px 0;
  }

}

/*ul.rc-sub-crumbs {

	list-style: none;
	margin: 33px 0 33px 0;
	padding: 0;

	@include font-size(18);

  & > li {

	padding: 0 25px;
	display: inline-block;

	& > a {

	  display: inline-block;
	  //color: $rc-sub-text-color;
	  color: #676767;
	  pointer-events: none;

	  &.active {
		pointer-events: auto;
		color: $rc-primary-link-color;
		border-bottom: 1px solid $rc-primary-link-color;
	  }

	}

  }

}*/

ul {

  list-style: none;
  margin: 0 0 1em 10px;
  padding: 0;

  li {

	line-height: 1.5;
	margin: 0 0 .5em 0;
  }

}

.verify-text-wrap p {
  color: $rc-footer-text-color;
  line-height: 1.5;
  margin: 0 0 1em 0;
  font-size: 1.1rem;
}





ul.rc-tos {

	margin: 0 0 25px 32px;

	li {

		color: #333333;
		line-height: 1.5;
		margin: .5em 0 0 0;

		&:before {
			float: left;
			display: inline-block;
			content: '\2014';
			margin: 0 0 0 -2em;
			background: transparent;
		}

	}

  &.rc-tos-uk {
	padding-left: 0;

	li {
	  &:before {
		display: none;
	  }
	}
  }

  &.rc-tos-au {

	li {
	  &:before {
		display: none;
	  }
	}
  }

	@include respond-to(largePhone) {
		margin: 0 0 25px 25px;
		font-size: 1.2rem;
	}

}


.nowrap {
  white-space: nowrap;
}

.bolder {
  font-weight: bold;
}

.font-normal {
	font-weight: normal;
}

/*
.fade-text {
  opacity: 0.5;
}*/

.tos {
	font-size: 1.6rem;
	font-family: $rc-font-light;
}

.tos-data {
	display: inline-block;
	width: calc(100% - 35px);
	vertical-align: top;
}

.trial-message {
	display: none;
	color: #E35A2F;
	font-size: 1rem!important;
}
