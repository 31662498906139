
$smartphone: 320px;
$smartphoneGalaxy: 375px;
$largePhone: 640px;
$largePhonePortrait: 640px;
$tabletPortrait: 768px;
$largePhoneGalaxy: 846px;
$largePhoneProMax: 926px;
$tabletLandscape: 1024px;
$largeScreen: 1440px;
$middleScreen: 1200px;
$desktop: 1023px;
$ipadProPortrait: 1024px;
$ipadProLandscape: 1366px;


@mixin respond-to($media) {

	@if $media == smartphone {
		@media (max-width: $smartphone) { @content; }
	}

	@if $media == smartphoneG {
		@media (max-width: $smartphoneGalaxy) { @content; }
	}

	@else if $media == tablet {
		@media (min-device-width: $tabletPortrait) and (max-device-width: $tabletLandscape) { @content; }
	}

	@else if $media == smallScreen {
		@media (max-width: $tabletPortrait) { @content; }
	}

	@else if $media == largePhonePortraitLandscape {
		@media (max-width: $largePhoneGalaxy) and (orientation: landscape),
		only screen and (max-device-width: $largePhone) { @content; }
	}

	@else if $media == largePhoneProMax {
		@media (max-width: $largePhoneProMax) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3),
		only screen and (max-device-width: $largePhone) { @content; }
	}

	@else if $media == smallScreenG {
		@media (max-width: $largePhoneGalaxy) { @content; }
	}

	@else if $media == smallScreenGLandscape {
		@media only screen and (max-width: $largePhoneGalaxy) and (orientation : landscape) { @content; }
	}

	@else if $media == smallDesktop {
		@media (max-width: $desktop) { @content; }
	}

	@else if $media == largeScreen {
		@media (max-width: $largeScreen) { @content; }
	}

	@else if $media == middleScreen {
		@media (max-width: $middleScreen) { @content; }
	}

	@else if $media == largePhone {
		@media (max-width: $largePhone) { @content; }
	}

	@else if $media == largePhonePro {
		@media (max-width: $largePhoneProMax) { @content; }
	}

	@else if $media == largePhonePortrait {
		@media (max-width: $largePhonePortrait) and (orientation : portrait) { @content; }
	}

	@else if $media == desktop {
		@media (min-width: 1024px) { @content; }
	}

	@else if $media == ipadpro {
		@media only screen
		and (min-device-width: $ipadProPortrait)
		and (max-device-width: $ipadProLandscape)
		and (-webkit-min-device-pixel-ratio: 1.5) {
			@content;
		}
	}

}



@keyframes display-none-transition {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}

@mixin rc-ui-display-none-transition() {
	animation: .2s display-none-transition;
}

@mixin rc-ui-transition() {
	transition: all ease .2s
}

@mixin rc-ui-transition-transform() {
	transition: transform ease .2s
}

@mixin rc-opacity-transition() {
	transition: opacity ease .2s
}

@mixin rc-appear() {
	animation: appear ease .2s;
}

@mixin rc-blink() {
	animation: blink ease .2s;
}

/*
@mixin rc-notblink() {
	animation: notblink ease .2s;
}*/

@mixin rc-disappear() {
	animation: disappear ease .2s;
}

@mixin rc-ui-textpulse() {
	animation: 1.5s textpulse;
}

@mixin rc-ui-popup-up() {
	animation: 1.2s invit-popup-up;
}

@mixin rc-ui-popup-out() {
	animation: 1.2s invit-popup-out;
}

@mixin rc-slow-down() {
	animation: 0.3s slow-down;
}

/*@keyframes textpulse {

	0% {
		color: inherit;
	}

	25% {
		color: $rc-orange-text;
		transform: scale(1.1);
	}

	100% {
		color: inherit;
	}

}*/


@keyframes invit-popup-up {
	from,60%,75%,90%,to {
		animation-timing-function:cubic-bezier(0.215,0.61,0.355,1);
	}
	from {
		opacity:0;
		transform:translate3d(0,3000px,0);
	}
	60% {
		opacity:1;
		transform:translate3d(0,-20px,0);
	}
	75% {
		transform:translate3d(0,10px,0);
	}
	90% {
		transform:translate3d(0,-5px,0);
	}
	to {
		transform:translate3d(0,0,0);
	}
}


@keyframes invit-popup-out {
	20% {
		transform:translate3d(0,10px,0);
	}
	40%,45% {
		opacity:1;
		transform:translate3d(0,-20px,0);
	}
	to {
		opacity:0;
		transform:translate3d(0,2000px,0);
	}
}


@mixin image2x() {
	@media (min--moz-device-pixel-ratio: 1.3),
	(-o-min-device-pixel-ratio: 2.6/2),
	(-webkit-min-device-pixel-ratio: 1.3),
	(min-device-pixel-ratio: 1.3),
	(min-resolution: 1.3dppx) {
		@content;
	}
}


@mixin font-face($style-name, $filepath) {
	@font-face {
		font-family: "#{$style-name}";
		src: url($filepath + '.eot');
		src: url($filepath + '.eot?#iefix') format('embedded-opentype'),
		url($filepath + '.woff') format('woff'),
		url($filepath + '.ttf') format('truetype'),
		url($filepath + '.svg') format('svg');
		font-weight: normal;
		font-style: normal;
	}
}


// =============================================================================
// font-size mixin with REM fallback
// =============================================================================

@mixin font-size($sizeValue: $rc-default-text-size) {
	font-size: $sizeValue + px;
	font-size: calc($sizeValue / 10) + rem ;
}


// =============================================================================
// clearfix mixin
// =============================================================================

@mixin clearfix() {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin center() {
	//-webkit-font-smoothing: antialiased;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

@mixin border-radius($radius) {
	border-radius: $radius;
	background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}


@mixin text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}


@mixin text-shadow-truncate {
	display: block;
	content: '';
	position: absolute;
	width: 60px;
	height: 27px;
	right: 1px;
	top: 6px;
	//border: 1px solid red;
	background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.32) 64%, rgba(255,255,255,1) 83%, rgba(255,255,255,1) 100%);
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.32) 64%,rgba(255,255,255,1) 83%,rgba(255,255,255,1) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.32) 64%,rgba(255,255,255,1) 83%,rgba(255,255,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
}

@mixin font-antialiasing {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: auto;
	// text-rendering: optimizeLegibility;
	// backface-visibility: hidden;
	// transform: translate3d(0,0,0);
	// transform-style: preserve-3d;
	// perspective: 1000;
}


@mixin navigation-list {
	list-style-type:none;
	padding:0;
	margin:0;
	overflow:hidden;
	> li{
		display:block;
		float:left;
		&:last-child{
			margin-right:0px;
		}
	}
}


@function black($opacity){
	@return rgba(0,0,0,$opacity);
}

@function white($opacity){
	@return rgba(255,255,255,$opacity);
}

@mixin box-emboss($opacity, $opacity2){
	box-shadow:white($opacity) 0 1px 0, inset black($opacity2) 0 1px 0;
}


@mixin triangle($direction, $size: 6px, $color: #222){
	content: '';
	display: block;
	position: absolute;
	height: 0; width: 0;
	@if ($direction == 'up'){
		border-bottom: $size solid $color;
		border-left: 1/2*$size solid transparent;
		border-right: 1/2*$size solid transparent;
	}
	@else if ($direction == 'down'){
		border-top: $size solid $color;
		border-left: 1/2*$size solid transparent;
		border-right: 1/2*$size solid transparent;
	}
	@else if ($direction == 'left'){
		border-top: 1/2*$size solid transparent;
		border-bottom: 1/2*$size solid transparent;
		border-right: $size solid $color;
	}
	@else if ($direction == 'right'){
		border-top: 1/2*$size solid transparent;
		border-bottom: 1/2*$size solid transparent;
		border-left: $size solid $color;
	}
}


@mixin transition($transition) {
	-moz-transition:    $transition;
	-o-transition:      $transition;
	-webkit-transition: $transition;
	transition:         $transition;
}


@mixin noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Chrome/Safari/Opera */
	-khtml-user-select: none; /* Konqueror */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
								  not supported by any browser */
}


@keyframes fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}

	to {
		opacity: 1;
		-webkit-transform: translate(-50%, 0%);
		transform: translate(-50%, 0%);
	}
}


@keyframes appear {
	from {
		opacity: 0;
		transform: translate(0, 100%);
	}

	to {
		opacity: 1;
		transform: translate(0, 0);
	}
}

@keyframes disappear {
	from {
		opacity: 1;
		transform: translate(0,0);
	}

	to {
		opacity: 0;
		transform: translate(0, 100%);
	}
}






@keyframes blink {
	0% {
		opacity: 0;

	}
	30% {
		opacity: .8;

	}
	60% {
		opacity: .5;

	}
	100% {
		opacity: .1;

	}
}

/*
@keyframes notblink {
	0% {
		opacity: 1;

	}
	100% {
		opacity: 0;
	}
}*/

@keyframes slow-down {
	0% {
		height: 0;
		opacity: 0;

	}
	80% {
		height: auto;
	}

	100% {
		opacity: 1;
	}
}






$close-svg-color:             		(#ccc);
$close-svg-color-hover:             (#fff);


//$balloon-bg:                    (#74747e) !default;
//$svg-color-frogger:             (#404040) !default;

@mixin close-svg-fill ($color){
	background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' stroke='#{$color}' stroke-width='3' viewBox='0 0 418 416'%3E%3Cg fill='#{$color}'%3E%3Cpath d='M356.1,60.9 C274.9,-20.3 143.2,-20.3 61.9,60.9 C-19.4,142.1 -19.3,273.8 61.9,355.1 C143.1,436.3 274.8,436.3 356.1,355.1 C437.4,273.9 437.3,142.1 356.1,60.9 Z M343.8,342.8 C269.5,417.1 148.5,417.1 74.2,342.8 C-0.1,268.5 -0.1,147.5 74.2,73.2 C148.5,-1.1 269.5,-1.1 343.8,73.2 C418.2,147.5 418.2,268.5 343.8,342.8 Z'/%3E%3Cpolygon points='293.2 112 208.8 196.2 124.8 112.4 113 124.2 197 208 113 291.8 124.8 303.6 208.8 219.8 293.2 304 305 292.2 220.6 208 305 123.8'/%3E%3C/g%3E%3C/svg%3E");
	background-size: 100% auto!important;
}

@mixin transform ($val) {
	-webkit-transform: $val;
	-moz-transform: $val;
	-ms-transform: $val;
	transform: $val;
}

@mixin transform-mob ($val) {
	-webkit-transform: $val!important;
	-moz-transform: $val!important;
	-ms-transform: $val!important;
	transform: $val!important;
}

@mixin transform-origin ($val) {
	-webkit-transform-origin: $val;
	-moz-transform-origin: $val;
	-ms-transform-origin: $val;
	transform-origin: $val;
}



@mixin svg-fill-left ($color){
	background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22#{$color}%22%20transform%3D%22rotate(-90 18 18)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
	background-size: 100% auto!important;
}

@mixin svg-fill-right ($color){
	background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22#{$color}%22%20transform%3D%22rotate(90 6 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
	background-size: 100% auto!important;
}

@mixin svg-fill-up ($color){
	background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22#{$color}%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
	background-size: 100% auto!important;
}

@mixin svg-fill-down ($color){
	background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22#{$color}%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
	background-size: 100% auto!important;
}

@mixin nth-child-between($first, $last, $times, $interval) {
	@for $i from 0 through $times {
		$first: $first + $interval;
		$last: $last + $interval;
		&:nth-child(n+#{$first}):nth-child(-n+#{$last}) {
			@content;
		}
	}
}
