.tooltip {

	background: $rc-tooltip-bg-color;
	border: 1px solid $rc-tooltip-border;
	border-radius: $rc-main-border-radius;
    padding: 25px 25px 13px 25px;
    text-align: left;
    margin: 10px auto 0 auto;
    width: 490px;
    position: relative;

	@include respond-to(smallScreen) {
		width: auto;
		margin-right: $rc-mobile-padding;
		margin-left: $rc-mobile-padding;
	}

	&:after,
	&:before {
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(250, 250, 250, 0);
		border-bottom-color: #fafafa;
		border-width: 6px;
		margin-left: -6px;
	}

	&:before {
		border-color: rgba(214, 214, 214, 0);
		border-bottom-color: #d6d6d6;
		border-width: 7px;
		margin-left: -7px;
	}

}

.tooltip-streamline {
	position: relative;
	padding: 15px 10px 11px 20px;
	background: #F8F8F8;
	color: #676767;
	border: none;
	margin-top: 14px;

	&:after,
	&:before {
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(250, 250, 250, 0);
		border-bottom-color: #F8F8F8;
		border-width: 6px;
		margin-left: -6px;
	}

	&:before {
		border-color: rgba(214, 214, 214, 0);
		border-bottom-color: #F8F8F8;
		border-width: 7px;
		margin-left: -7px;
	}

	&.step-inner {
		margin: 12px 5px;

		@include respond-to(desktop) {
			margin: 12px 10px 0 64px;
		}
	}

	h4 {
		font-size: 1.3rem;
		color: #676767;
		font-weight: 600;
		margin: 0 0 10px 0;
	}

	p {
		color: #676767;
		font-size: 1.2rem;
	}
}

.error-tooltip-wrap {
	opacity: 0;
	bottom: 100%;
	margin-bottom: 11px;
	pointer-events: none;
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	z-index: 10;
	transform: translate3d( 0, 0, 0);

	.error-tooltip {
		display: inline-block;
		background: transparent;
		//background: #fa0000;
		padding: 11px 12px;
		color: #fff;
		border-radius: $rc-main-border-radius;
		bottom: 0;
		margin-bottom: 11px;
		@include transform(translate(0, 0));
		@include transform-origin(top);

		&:before {
			display: block;
			content: "";
			position: absolute;
			//@include svg-fill-up ($color: transparent);
			width: 18px;
			height: 6px;
			@include transform(translate(-50%, 0));
			@include transform-origin(top);
			bottom: 0;
			left: 50%;
			margin-bottom: -5px;

		}
	}
}



.validate {

	position: relative;

	/*---------------CSS Hack for Edge----------------*/
	.error-tooltip {
		background: #fa0000;

		&:before {
			//@include svg-fill-up ($color: #fa0000);
			background: url(../images/arrow-tooltip.svg) no-repeat center;
			background-size: contain;
		}
	}
	/*-------------------------------------------------*/

	input {
		border: 1px solid #fa0000;
		&:focus ~ .error-tooltip-wrap {
			opacity: 1;
		}
	}

	&.select-row {
		border: 1px solid #fa0000;
		//select:focus + .error-tooltip-wrap {
		//	opacity: 1;
		//	@include rc-ui-transition();
		//}
	}


	//&.select {
	//	border: 1px solid #fa0000;
	//	//select:focus + .error-tooltip-wrap {
	//	//	opacity: 1;
	//	//	@include rc-ui-transition();
	//	//}
	//}


	input {
		border: 1px solid #fa0000;
		&:focus ~ .error-tooltip-wrap > .error-tooltip {
			@include transform(translateY(15px));
			@include rc-ui-transition();
		}
	}

	.fit {
		width: 200%;
		margin-left: -50%;
	}
}


.have-info-wrapper {

	overflow: visible;
	padding: 5px;
	display: inline-block;
	vertical-align: middle;

	//-webkit-overflow-scrolling: touch;

	.have-info-icon {
		width: 22px;
		height: 22px;
		border-radius: 50%;
		padding: 0;
		margin: 0;
		cursor: pointer;
		transition: all .2s ease;
		position: relative;
		//z-index: 9;

		&:before {
			display: block;
			content: "";
			background: url('../images/info_icon.svg')content-box no-repeat center center;
			width: 22px;
			height: 22px;

		}

	}

	.have-info-content {

		border-radius: $rc-main-border-radius;
		color: #8F8F8F;
		position: absolute;
		white-space: nowrap;
		z-index: 300;
		opacity: 0;
		pointer-events: none;
		padding: 20px;
		font-size: 1.4rem;
		background: #fff;
		text-align: left;

		-webkit-box-shadow: 0px 0px 30px -6px rgba(0,0,0,0.38);
		-moz-box-shadow: 0px 0px 30px -6px rgba(0,0,0,0.38);
		box-shadow: 0px 0px 30px -6px rgba(0,0,0,0.38);

		h2 {
			color: #333 !important;
			border: none !important;
			padding: 11px 0 0 0 !important;
			font-weight: normal !important;
		}

		table tr {

			th {
   				font-size: 1.2rem;
				span {
					display: inline-block;
					width: 100px;
				}
   			}
			td {
				border-top: 1px solid #E4E5E6;
				b {
					font-weight: normal !important;
					color: #676767;
					display: inline-block;
    				width: 104px;
				}
			}
		}

		table tr:first-child {
			td {
				border: none;
			}
		}

		&:before {
			filter: alpha(opacity=0);
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
			-moz-opacity: 0;
			-khtml-opacity: 0;
			opacity: 0;
			pointer-events: none;
			content: '';
			position: absolute;
			z-index: 10;
			background-size: 100% auto!important;

		}
		& > p:last-child,
		& > ul:last-child
		 {
			margin-bottom: 0;
		}
		ul {
			li {
				&:before {
				float: left;
				display: inline-block;
				content: '';
				margin: 9px 0 0 -9px;
				width: 3px;
				height: 3px;
				border-radius: 3px;
				background: $rc-corporate-orange;
				}
			}
		}
	}

	&:hover {

		.have-info-content {
			opacity: 1;
			pointer-events: auto;
			@include rc-ui-transition();

			&:before {
				opacity: 1;
				pointer-events: auto;
				bottom: 0;
				margin-bottom: -6px;
			}
		}
	}
}

.up {
	.have-info-content {
		bottom: 100%;
		left: 50%;
		margin-bottom: 11px;
		@include transform(translate(-50%, 10px));
		@include transform-origin(top);

		&:before {
			//@include svg-fill-up ($color: #fff);
			background: url(../images/arrow-tooltip-up.svg) no-repeat center;
			width: 18px;
			height: 6px;
			bottom: 100%;
			left: 50%;
			margin-bottom: 5px;
			@include transform(translate(-50%, 10px));
			@include transform-origin(top);

		}

		@include respond-to(smallScreenG) {
			top: auto;
			bottom: auto;
			left:auto;
			right: auto;
		}

	}

	&:hover {
		.have-info-content {
			@include transform(translate(-50%, 0));

			&:before {
				@include transform(translate(-50%, 0));
			}
		}
	}

}


.down {
	.have-info-content {
		left: 50%;
		margin-top: 11px;
		top: 100%;
		@include transform(translate(-50%, -10px));

		&:before {
			//@include svg-fill-down ($color: #fff);
			background: url(../images/arrow-tooltip-down.svg) no-repeat center;
			width: 18px;
			height: 6px;
			left: 50%;
			margin-top: 5px;
			top: 100%;
			@include transform(translate(-50%, -10px));

		}

		@include respond-to(smallScreenG) {
			top: auto;
			bottom: auto;
			left:auto;
			right: auto;
		}
	}

	&:hover {
		.have-info-content {
			@include transform(translate(-50%, 0));

			&:before {
				@include transform(translate(-50%, 0));
				top: 0;
				margin-top: -6px;
			}
		}
	}

}

.left {

	.have-info-content {
		display: block!important;
		margin-right: 11px;
		right: 100%;
		top: 50%;
		@include transform(translate(10px, -50%));

		&:before {
			//@include svg-fill-left ($color: #fff);
			background: url(../images/arrow-tooltip-left.svg) no-repeat center;
			width: 6px;
			height: 18px;
			margin-right: 5px;
			right: 100%;
			top: 50%;
			@include transform(translate(10px, -50%));

		}

		@include respond-to(smallScreenG) {
			top: auto;
			bottom: auto;
			left:auto;
			right: auto;
			display: none;
		}

	}

	&:hover {
		.have-info-content {
			@include transform(translate(0, -50%));

			&:before {
				@include transform(translate(0, -50%));
				right: 0;
				margin-right: -6px;
			}
		}
	}
}

.right {

	.have-info-content {
		left: 100%;
		margin-left: 11px;
		top: 50%;
		@include transform(translate(-10px, -50%));

		&:before {
			//@include svg-fill-right ($color: #fff);
			background: url(../images/arrow-tooltip-right.svg) no-repeat center;
			width: 6px;
			height: 18px;
			left: 100%;
			margin-left: 5px;
			top: 50%;
			@include transform(translate(-10px, -50%));

		}

		@include respond-to(smallScreenG) {
			top: auto;
			bottom: auto;
			left:auto;
			right: auto;
		}

	}

	&:hover {
		.have-info-content {
			@include transform(translate(0, -50%));

			&:before {
				@include transform(translate(0, -50%));
				left: 0;
				margin-left: -6px;
			}
		}
	}
}


/*Mobile*/
.down-mob {

	@include respond-to(smallScreenG) {
		.have-info-content {
			left: 50%;
			margin: 11px 0 0 0;
			top: 100%;
			right: auto;
			@include transform-mob(translate(-50%, -10px));
		}

		&:hover {
			.have-info-content {
				@include transform-mob(translate(-50%, 0));
				left: 50%;
				margin: 11px 0 0 0;
				top: 100%;

				&:before {
					//@include svg-fill-down ($color: #fff);
					background: url(../images/arrow-tooltip-down.svg) no-repeat center;
					width: 18px;
					height: 6px;
					left: 50%;
					@include transform-mob(translate(-50%, 0));
					top: 0!important;
					margin: -6px 0 0 0;
				}
			}
		}
	}

	@include respond-to(smartphoneG) {
		.have-info-content {
			left: 40%;
			@include transform-mob(translate(-40%, -10px));
		}

		&:hover {
			.have-info-content {
				@include transform-mob(translate(-40%, 0));
				left: 40%;

				&:before {
					left: 40%;
					@include transform-mob(translate(-40%, 0));
				}
			}
		}
	}
}

.up-mob {

	@include respond-to(smallScreenG) {
		.have-info-content {
			left: 50%;
			margin: 0 0 11px 0;
			bottom: 100%;
			@include transform-mob(translate(-50%, -10px));
		}

		&:hover {
			.have-info-content {
				@include transform-mob(translate(-50%, 0));
				left: 50%;
				margin: 0 0 11px 0;
				bottom: 100%;

				&:before {
					//@include svg-fill-up ($color: #fff);
					background: url(../images/arrow-tooltip-up.svg) no-repeat center;
					width: 18px;
					height: 6px;
					left: 50%;
					@include transform-mob(translate(-50%, 0));
					top: 100%;
					margin: 0 0 -6px 0;
				}
			}
		}
	}
}


.left-mob {

	@include respond-to(smallScreenG) {
		.have-info-content {
			right: 100%;
			margin: 0 11px 0 0;
			top: 50%;
			@include transform-mob(translate(10px, -50%));
		}

		&:hover {
			.have-info-content {
				@include transform-mob(translate(0, -50%));
				top: 50%;
				margin: 0 11px 0 0;

				&:before {
					//@include svg-fill-left ($color: #fff);
					background: url(../images/arrow-tooltip-left.svg) no-repeat center;
					width: 6px;
					height: 18px;
					top: 50%;
					@include transform-mob(translate(0, -50%));
					left: 100%;
					margin: 0;
				}
			}
		}
	}

}


.right-mob {

	@include respond-to(smallScreenG) {
		.have-info-content {
			left: 100%;
			margin: 0 0 0 11px;
			top: 50%;
			@include transform-mob(translate(-10px, -50%));
		}

		&:hover {
			.have-info-content {
				@include transform-mob(translate(0, -50%));
				top: 50%;
				margin: 0 0 0 11px;
				left: 100%;

				&:before {
					//@include svg-fill-right ($color: #fff);
					background: url(../images/arrow-tooltip-right.svg) no-repeat center;
					width: 6px;
					height: 18px;
					top: 50%;
					@include transform-mob(translate(0, -50%));
					left: 0;
					margin: 0 0 0 -6px;
				}
			}
		}
	}
}


.small {
	.have-info-content {
		white-space: normal;
		min-width: 80px;
	}
}

.medium {
	.have-info-content {
		white-space: normal;
		min-width: 180px;
	}
}

.large {
	.have-info-content {
		white-space: normal;
		min-width: 300px;
		@include respond-to(largePhone) {
			min-width: 210px;
		}
		@include respond-to(smartphone) {
			min-width: 170px;
		}
	}
}

table {

	width: 100%;

	tr {

		border-top: 1px solid rgba(255, 255, 255, 0.15);

		&:first-child {
			border: none;
		}

		td {
			padding: 11px 3px;
			text-align: center;

			&:first-child {
				text-align: left;
			}
		}


		th {
			padding: 11px 3px;
			text-align: center;
			font-weight: normal;
		}

	}

}


/* TODO Refactor */
.table-list,
.head-table {
	div {

		width: calc(33.333%);
		float: left;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border: 1px solid rgba(255, 255, 255, 0.15);
		border-top: none;

		&:first-child {
			text-align: left;
			border-left: none;
			border-right: none;
			padding-left: 10px;
		}

		&:last-child {
			border-left: none;
			border-right: none;
		}
	}
}

.table-list {
	&:last-child {
		div {
			border-bottom: 0;
		}
	}
}

.head-table {
	div {
		height: 40px;
		line-height: 1.6rem;
	}
}
