

form {
	//padding: 0 0 44px;
}

input::-ms-clear,
input::-ms-reveal {
    display: none;
}

::-webkit-input-placeholder { color: $rc-placeholder-text-color; }
::-moz-placeholder          { color: $rc-placeholder-text-color; }
:-moz-placeholder           { color: $rc-placeholder-text-color; }
:-ms-input-placeholder      { color: $rc-placeholder-text-color; }

input, select {

	padding: 10px 12px;
	color: $rc-input-text-color;
	border-radius: 0;
	border: 1px solid $rc-input-border-color;
	background: $rc-input-bg-color;
	width: 620px;


	@include rc-ui-transition();

	@include respond-to(smallScreen) {
		width: 100%;
	}

	&.small {
		width: 154px;
		text-align: center;
	}

	&.medium {
		width: 302px;
		@include respond-to(smallScreen) {
			width: 100%;
		}
	}

	&:focus {
		//border: 1px solid $rc-focused-input-border-color;
		outline: none;

	}

	&:disabled {
		background: $rc-main-bg-color;
		color: $rc-sub-text-color;
	}
}

/*
.rc-labeled-checkbox {
	label {
		margin: -20px 0 0 28px;
    	display: block;
	}
}*/


.rc-row,
.rc-col {

	&.cc:after {
		display: block;
		position: absolute;
		top: 6px;
		right: 10px;
		width: 49px;
		height: 24px;
		content: '';
		opacity: 0;
		background-repeat: no-repeat;
		@include rc-opacity-transition();
	}

	&.mc:after {
		opacity: 1;
		background-image: url(../images/cc-mastercard.svg);
		background-position: 100% 1px;
	}

	&.vs:after {
		background-image: url(../images/cc-visa.svg);
		background-position: 100% 6px;
		opacity: 1;
	}

	&.ae:after {
		background-image: url(../images/cc-americanexpress.svg);
		background-position: 100%;
		opacity: 1;
	}

    &.dc:after {
      background-image: url(../images/cc-discover.svg);
      background-position: 100%;
      opacity: 1;
    }

}

.labeled-checkbox {
	margin: 22px 0;
}


textarea {
	line-height: normal;
	padding: 5px 12px;
	color: $rc-input-text-color;
	//border-radius: $rc-input-radius;
	//border: 1px solid $rc-input-border-color;
	background: $rc-input-bg-color;
	width: 490px;
	resize: none;
    min-height: 70px;

	&:focus {
		//border: 1px solid $rc-focused-input-border-color;
		outline: none;
	}
}

input[type='number'],
input[type='tel'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}


input[type='number'],
input[type='text'],
input[type='tel'],
input[type='email'],
input[type='password'],
input[type='file'],
select {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	box-shadow: none;
	display: block;
	margin: 0 auto 20px auto;

	@include respond-to(largePhonePortrait) {
		margin-bottom: 9px;
	}

}

input[type="file"] {
	width: 100%;
	margin: 0;
	position: absolute;
	bottom: 0;
	opacity: 0;
}


input[type='radio'] {

	width: 20px;
	height: 20px;
	position: absolute;
	opacity: 0;

	&.radio-button {

		//position: relative;

	    &:after {
			width: 20px;
		    height: 20px;
		    border-radius: 20px;
		    top: -2px;
		    left: -1px;
		    position: absolute;
		    content: "";
		    display: inline-block;
		    visibility: visible;
		    //border: 1px solid $rc-fieldset-border-color;
		    background: #fff;
		    z-index: 50;
		    @include rc-ui-transition();
	    }

	    &:checked:after {
	        background: $rc-primary-btn-bg;
	        border-color: $rc-primary-btn-bg;
	    }

	    &:checked:before {
		    width: 6px;
		    height: 6px;
		    border-radius: 6px;
		    top: 5px;
		    left: 6px;
		    position: absolute;
	        background-color: #fff;
	        content: '';
	        display: inline-block;
	        visibility: visible;
	        z-index: 100;
	        @include rc-ui-transition();
	    }

	}
}



.radio-label {
	display: block;
    position: relative;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;

	&:before {
		display: block;
		content: '';
		position: absolute;
		width: 20px;
		height: 20px;
		top:0;
		left: 0;
		border: 1px solid $rc-primary-btn-bg;
		border-radius: 50%;
		background: #fff;
	}

	&:after {
		display: block;
		content: '';
		position: absolute;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: $rc-primary-btn-bg;
		top: 4px;
		left: 4px;
		opacity: 0;
		transition: all ease .15s
	}
}

input[type='radio']:checked +.radio-label:after {
	opacity: 1;
}


input[type='checkbox'] {

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	position: relative;
	margin: 0 8px -5px 0;
	display: inline-block;
	width: 20px;
	height: 20px;
	border-radius: 1px;
	//border: 1px solid #cacbcc;
	cursor: pointer;

	&:after {
		display: inline-block;
		content: '';
		width: 8px;
		height: 5px;
		position: absolute;
		top: 5px;
		left: 5px;
		border: 1px solid $rc-primary-btn-color;
		border-top: none;
		border-right: none;
		background: transparent;
		opacity: 0;
		transform: rotate(-45deg);
	}

	&:checked {
		background: $rc-primary-btn-bg;
		border: 1px solid $rc-primary-btn-bg;
	}

	&:checked:after {
		opacity: 1;
	}

	&:disabled {
		background: #d1d1d1;
		border-color: #d1d1d1;
	}

}

.rc-search {
	margin: 0 !important;
	width: 100%;
	border-radius: 35px;
}


.rc-labeled-checkbox {
	padding: 22px 0;

	label {
		display: inline-block;
		width: calc(100% - 35px);
		vertical-align: top;
	}
}


a.user-import-link {

	display: block;
	width: 165px;
	height: 40px;
	background: #fff;
	border: 1px solid $rc-input-border-color;
	border-radius: $rc-input-radius;
	cursor: pointer;
	//color: $rc-input-border-color;
	color: $rc-inport-links-color;

	@include rc-ui-transition();

	.g-signin2 {
		position: absolute;
		width: 150px;
		height: 37px;
		opacity: 0;
		top: 0;

		> div {
			width: 150px !important;
			height: 37px !important;
		}

	}

	span {
		display: inline-block;
		height: 37px;
		vertical-align: middle;
	}

	&:before {
		display: inline-block;
		content:'';
		width: 23px;
		height: 24px;
		vertical-align: middle;
		margin: 6px 5px 7px 0;
		//background-image: url(../images/import-google.svg);
		background-image: url(../images/import-google-color.svg);
		background-repeat: no-repeat;
	}

	&:hover {
		border: 1px solid $rc-focused-input-border-color;
		//color: $rc-inport-links-color;
	}

	/*&:hover:before {
		background-image: url(../images/import-google-color.svg);
	}*/

	/*&.ms:before {
		background-image: url(../images/import-ms.svg);
	}*/

	&.ms:before {
		background-image: url(../images/import-ms-color.svg);
	}


}


@supports (-ms-ime-align:auto) and (-webkit-text-stroke:initial) {
	input, select { line-height:0; }
}

.svg-close-cross {
	position: absolute;
	display: block;
	width: 30px;
	height: 30px;
	cursor: pointer;
	padding: 2px;
	transition: all ease .15s;

	&:before {
		display: block;
		content: "";
		width: 24px;
		height: 24px;
		//@include close-svg-fill($close-svg-color);
		background: url(../images/close-cross.svg) no-repeat center;
		background-size: 100% auto!important;
	}

}
