// =============================================================================
// RC Layout
// =============================================================================


$rc-layout-max-width: 	985px;
$rc-side-padding:		19px;
$rc-layout-padding:		22px;


//$rc-layout-padding: 	15px 12px;
//$rc-header-padding: 	25px 12px;
//$rc-footer-height: 	110px;
$rc-mobile-padding: 	15px;

// =============================================================================

.hidden {
  display: none!important;
}

/* todo
.hide {
  display: none;
}*/

.active {
  display: block!important;
}

.flex-active {
	display: flex!important;//TODO
}

.active-inline {
  display: inline-block !important;
}

.not-visible {
	visibility: hidden;
	max-height: 0;
	overflow: hidden;
}

.visible {
	visibility: visible;
	max-height: 1000px;
	transition: .5s linear;
}


.appear {
  @include rc-appear();
}

.blink {
  @include rc-blink();
}

.notblink {
  //@include rc-notblink();
  opacity: 0;
  transition: .2s linear;
}

.disappear {
  @include rc-disappear();
}

.rc-pulsestart {
  .rc-textpulse {
	@include rc-ui-textpulse();
  }
}







.flex {

	display: flex;

	&.row {
		flex-direction: row;
	}

	&.column {
		flex-direction: column;
	}

	&.flex-end {
		justify-content: flex-end;
	}

	&.space-between {
		justify-content: space-between;
	}

	&.space-around {
		justify-content: space-around;
	}

	&.center {
		justify-content: center;
	}

	&.start {
		justify-content: start;
	}

	&.align-left {
		align-items: flex-start;
	}

	&.align-right {
		align-items: flex-end;
	}

	&.align-center {
		align-items: center;
	}

	&.flex-wrap {
		flex-wrap: wrap;
	}

}



*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
	width: 100%;
	min-height: 100%;
  height: 1px;
}

body {
	width: 100%;
	min-height: 100%;
	height: 1px;
	text-align: center;
	position: relative;
	background: $rc-main-bg-color;
}

	.content-wrapper {
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-pack: justify;
		justify-content: space-between;
		//height: 100%;
		min-height: 100%;
    position: relative;
	}

		.rc-layout {


			.rc-layout-inner {

				max-width: $rc-layout-max-width;
				margin: 0 auto;
				position: relative;

				@include respond-to(smallDesktop) {
					padding: 0 19px;
				}

			}
		}


		.main-section {
			-ms-flex-positive: 1;
			flex-grow: 1;
			//height: 100%;
            padding: 0 0 44px 0;
            position: relative;
            margin-bottom: 102px;
            @include respond-to(smallDesktop) {
                margin-bottom: 168px;
            }
		}


.w50 {
  width: 50%;
}

.rc-row {

  position: relative;

  .rc-col {
	display: block;
  }


}

.rc-table {

    .rc-table-rowset {
        border-radius: $rc-main-border-radius;
        background: #fff;
    }

  .rc-table-row {

	text-align: left;
	//border-top: 1px solid #dedfe0;
	padding: 22px;
	background: #fff;
	border-bottom: 1px solid rgba(226, 226, 226, .7);

	  > * {
		@include font-size(16);
	  }

	&.header {
	  padding: 25px;
	  border: none;
	  margin: 0 0 11px 0;
      border-radius: $rc-main-border-radius;
      background: #fff;
	}

	&.disabled {
	  background: #f9f9f9;
	}

	& > div {

	  display: table-cell;
	  vertical-align: middle;
	  float: left;

	  input[type=checkbox] {
		margin-right: 12px;
	  }

	  label {
		margin: -2px 0 0 0;
	  }

	}

	@include clearfix();

  }

}


.shadowed {
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.1);
}


fieldset,
.rc-block {

	width: 664px;
	padding: 0px 88px 0 88px;
	margin: 0 auto 25px auto;
	text-align: left;

	&.filled {

		background: $rc-fieldset-bg-color;
		border: 1px solid $rc-fieldset-border-color;
		border-radius: $rc-input-radius;
		padding: 35px 35px 30px 35px;
		width: 490px;

		@include respond-to(smallScreen) {
			border-radius: 0 !important;
			border: none !important;
			//padding: $rc-mobile-padding $rc-mobile-padding $rc-mobile-padding $rc-mobile-padding !important;
		}

	}

	&.tos {
		margin: 11px auto 22px auto;

		@include respond-to(largePhone) {
			margin: 11px auto 22px auto;
		}
	}


	@include respond-to(smallScreen) {
		width: 100% !important;
        padding-left: $rc-mobile-padding !important;
        padding-right: $rc-mobile-padding !important;
	}

}


.rc-block-white {

	background: #fff;
    border: 1px solid #e4e5e6;
	//border-radius: 4px;
	//box-shadow: 0 2px 6px 0 rgba(51,51,51,0.1);
	padding: 13px 25px;
	text-align: left;
	margin: 22px auto 22px;
	max-width: 650px;
	position: relative;

	p {
		font-size: 1.6rem;
		line-height: 1.5;
	}

	.icon {
		display: block;
		margin: 0 auto;
	}

}

fieldset {
  padding: 0 22px 22px;
  margin: 0 auto;
  border: none;
  position: relative;

  h2 {
	font-size: 2.4rem;
  }

}

.buttons {
  form {
	padding-top: 10px;
	display: inline-block;
	margin: 0 2px;
  }
  &.filled {
	background: $rc-fieldset-bg-color;
	padding: 22px 0;
  }
}

.iosddi,
noscript {
  position: absolute;
  bottom: 0;
  opacity: 0;
}

.unscroll {
  position: fixed;
}

.grecaptcha-badge {
	z-index: 256;
}
