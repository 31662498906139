/*TODO*/
.preloader {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 256;

	.shadow {
		width: 100%;
		height: 100%;
		background: #fff;
		border-radius: 4px;
		opacity: 0.3;
		position: absolute;
		left: 0;
		top: 0;
	}

	& > h2 {
		margin: 0 0 0.5rem;
		color: #000;
		font-weight: 600;
		opacity: 0.8;
	}

	&.active-flex {
		display: flex;
	}
}


.rc-preloader {

	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 256;
	background: #000;
	opacity: .6;
	-webkit-transform: translate3d(0,0,0);
	z-index: 300;
	visibility: hidden;

	& > div {

		width: 200px;

		@include  center();

		& > h2 {
			margin: 0 0 .5em 0;
			color: $rc-main-bg-color;
		}
	}

	&.active {
		display: block;
		visibility: visible;
	}

	&.white-preloader {
		background: transparent;
		opacity: 1;

		.shadow {
			width: 100%;
			height: 100%;
			background: #fff;
			border-radius: 4px;
			opacity: 0.3;
		}

		h2 {
			color: #000;
			opacity: 0.8;
		}

		.loading-block .loading-circle {
			background: #000;
			opacity: 0.8;
		}
	}

}


.loading-block {

    display: flex;
    justify-content: center;
    align-items: center;
    height: 19px;

    .loading-circle {
        width: 16px;
        height: 16px;
        //border: 1px solid #000;
        background-color: #fff;
        border-radius: 50%;
        animation-duration: 1.2s;
        animation-direction: normal;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        margin: 0 2px 1px 0;

        @for $i from 1 to 6 {
            &:nth-child(#{$i}) {
                animation-name: screenloader-#{$i};
            }
        }

    }

}


$time-step: 100/17;
$opacity-step: 0.2;

@mixin opacity_changes($start_num, $end_num, $opacity_start) {
    @for $i from $start_num to $end_num {
        #{$time-step * $i + '%'} {
            opacity: #{$opacity_start - $opacity-step * $i};
        }
    }
}
@mixin opacity_not_changes($start_num, $end_num, $value) {
    @for $i from $start_num to $end_num {
        #{$time-step * $i + '%'} {
            opacity: $value;
        }
    }
}
@mixin animation_step($step, $value) {
    #{$step + '%'} {
        opacity: $value;
    }
}

@keyframes screenloader-1 {
    @include opacity_changes(0, 5, 1);
    @include opacity_not_changes(5, 12, 0);
    @include opacity_not_changes(12, 18, 1);
}
@keyframes screenloader-2 {
    @include animation_step(0, 0);
    @include opacity_changes(1, 6, 1.2);
    @include opacity_not_changes(6, 11, 0);
    @include opacity_not_changes(12, 17, 0.8);
    @include animation_step(100, 0);
}
@keyframes screenloader-3 {
    @include opacity_not_changes(0, 2, 0);
    @include opacity_changes(2, 7, 1.4);
    @include opacity_not_changes(7, 10, 0);
    @include animation_step($time-step * 11, 1);
    @include animation_step($time-step * 12, 0.8);
    @include opacity_not_changes(13, 16, 0.6);
    @include opacity_not_changes(16, 18, 0);
}
@keyframes screenloader-4 {
    @include opacity_not_changes(0, 3, 0);
    @include animation_step($time-step * 4, 1);
    @include opacity_not_changes(5, 9, 0.8);
    @include animation_step($time-step * 9, 0);
    @include opacity_changes(10, 15, 3);
    @include opacity_not_changes(15, 18, 0);
}
@keyframes screenloader-5 {
    @include opacity_not_changes(0, 4, 0);
    @include opacity_not_changes(4, 8, 1);
    @include opacity_changes(8, 13, 2.6);
    @include opacity_not_changes(13, 17, 0);
    @include animation_step($time-step * 17, 0);
}
