//WARNINGS-ROWS-VARIABLES (1 row has 4 warnings blocks)
$max-rows: 100;

$element-background: #fff;
$tooltip-background: #f4f4f4;
$tooltip-arrow: #eeeeee;
$font-color: #2f2f2f;
$line-color: #979797;
$block-separator: #EBEBEB;
$ok-color: #00875A;
$warning-color: #FFAB00;
$error-color: #DE350B;
$information-color: #0684BD;
$open-label: #0684BD;
$event-arrow: #585858;
$description-background: #e1f1f9;
$block-status: #BDBDBD;


html {
	overflow-x: hidden;
}

.covid,
.info-banner {
	padding: 15px 10px;
	background: #0085c5;

	p {
		margin: 0;
		font-size: 1.4rem;
		line-height: 1.8rem;
		font-family: $rc-font-bold;
		color: white;

		a {
			color: white;
			&:hover {
				text-decoration: underline;
			}
		}
    }
    hr {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.content-wrapper {
	justify-content: unset;
}

.rc-layout .rc-layout-inner {
	max-width: 1050px;
	margin: 0 auto;
	padding: 0 10px;

	.main-section-vue {
		flex: 1 0 auto;
	}
}

header.rc-layout {


	.rc-layout-inner {

		& > * {
			width: auto;
		}

		.login-link {
			text-align: right;
			position: absolute;
			right: 0;
			top: 5px;
			width: auto;


			a {
				font-family: $rc-font-normal;
				font-size: 1.3rem;
				color: #fff;
				padding-left: 20px;
				cursor: pointer;

				&:before {
					position: absolute;
					content: '';
					left: 0;
					top: -1px;
					width: 13px;
					height: 15px;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					background-origin: content-box;
					background-image: url("../images/login-icon-white.svg");
				}
			}
		}

	}
}

.dashboard-title-wrapper {
	background: $element-background;
	margin-bottom: 50px;

	.dashboard-title {
		padding: 20px 10px;


		&:after {
			content: '';
			display: block;
			margin: 2px 0;
			position: absolute;
			left: 10px;
			top: 0;
			width: calc(100% - 20px);
			border-bottom: thin solid $line-color;

			@include respond-to(largePhonePortrait){
				left: 0;
				width: 100%;
			}
		}

		h1 {
			margin: 0 auto;
			font-size: 2.8rem;
			text-align: left;
			position: relative;
			text-transform: capitalize;
		}

		.core-services-info {
			justify-content: space-between;
		}

		input[class=serv-tab1] {
			display: none;

		}

		.serv-label {
			position: relative;
			color: $font-color;
			text-transform: capitalize;
			cursor: pointer;
			-webkit-tap-highlight-color: transparent;
		}

		.serv-label1 {
			color: $open-label;
			font-weight: 600;
			border-bottom: 2px solid $open-label;
			display: inline-flex;
			justify-content: center;
			padding: 5px 0;
		}

		.labels {
			width: 165px;

		}
	}
}

.dashboard-title,
.dashboard-wrapper {

	.have-info-wrapper {
		padding: 0 5px;
		display: block;

		.have-info-icon {
			width: 22px;
			height: 18px;
			border-radius: 0;
			margin: 0;
			cursor: initial;
			transition: all .2s ease;
			position: relative;
		}

		.have-info-icon:before {
			background-image: url("../images/dashboard_ok.svg");
			width: 22px;
			height: 18px;
			background-position-x: 2px;
		}

		&.board-ok > .have-info-icon:before {
			background-image: url("../images/dashboard_ok.svg");
		}

		&.board-warn > .have-info-icon:before {
			background-image: url("../images/dashboard_warning.svg");
		}

		&.board-error > .have-info-icon:before {
			background-image: url("../images/dashboard_error.svg");
		}

		&.board-information > .have-info-icon:before {
			background-image: url("../images/dashboard_information.svg");
		}

		&.board-empty > .have-info-icon:before {
			background-image: none;
			content: "\2014";
			font-size: 1.2rem;
			text-align: center;
		}

		&.board-ok .info-icon__tooltip > p span {
			color: $ok-color;
		}

		&.board-warn .info-icon__tooltip > p span {
			color: $warning-color;
		}

		&.board-error .info-icon__tooltip > p span {
			color: $error-color;
		}

		&.board-information .info-icon__tooltip > p span {
			color: $information-color;
		}


		.have-info-content {
			background: $tooltip-background;
			box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
			padding: 15px;

			.info-icon__tooltip > p {
				color: $font-color;
				font-size: 1.2rem;
				margin: 0 0 11px;
				text-align: left;

				&:last-of-type {
					margin: 0;
				}
			}
		}

		&.left {

			.have-info-content:before {
				background: url(../images/arrow-tooltip-left-grey.svg) no-repeat center;
				width: 8px;
				height: 21px;
				margin-right: -8px;
			}
		}

		&.down {

			.have-info-content{
				transform: translate(-95%, -15px);

				&:before {
					background: url(../images/arrow-tooltip-down-grey.svg) no-repeat center;
					left: 95%;
					width: 22px;
					height: 8px;
					margin-top: -7px;
				}
			}
			&:hover {
				.have-info-content {
					transform: translate(-95%, 0px);
				}
			}

		}

		&.non-active-ico {

			.have-info-icon {
				cursor: default;
			}

			&:hover {
				.have-info-content {
					display: none!important;
				}
			}
		}

		@include respond-to(smallDesktop) {

			&:hover {
				.have-info-content {
					display: none!important;
				}
			}
		}

	}

}

.dashboard-wrapper {
	position: relative;

	.dashboard-ok,
	.dashboard-warn,
	.dashboard-error,
	.dashboard-empty {
		display: block;
		width: 30px;
		height: 18px;
		padding: 0 5px;
		flex-shrink: 0;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-origin: content-box;
		background-image: url("../images/dashboard_ok.svg");
	}

	.dashboard-warn {
		background-image: url("../images/dashboard_warning.svg");
	}

	.dashboard-error {
		background-image: url("../images/dashboard_error.svg");
	}

	.dashboard-empty {
		background-image: none;
		content: "\2014";
		text-align: center;
	}


	//SERVICES TAB
	.services-tab {
		width: 100%;
		min-width: 100%;

		/*Warnings block*/
		.warning-block {
			position: relative;

			.warning-label {
				position: relative;
				flex-basis: calc(25% - 4px);
				background: $element-background;
				border-radius: 4px;
				margin: 0 2px 6px;
				font-size: 1.4rem;
				font-weight: 500;
				color: $font-color;
				cursor: pointer;

				.warning-name {
					flex-grow: 1;
					text-align: left;
					padding: 10px 10px 10px 0;

					max-width: 124px;
					word-break: break-word;
				}

				.location-lbl {
					position: absolute;
					right: 25px;
					text-align: right;
					padding: 2px 3px;
					border: thin solid $line-color;
					border-radius: 2px;
					font-size: 0.9rem;
					text-transform: uppercase;
				}

				.close-lbl {
					width: 10px;
					height: 10px;
					position: absolute;
					right: 10px;


					&:after {
						position: absolute;
						right: 1px;
						content: '';
						width: 8px;
						height: 8px;
						border-left: 1px solid $event-arrow;
						border-bottom: 1px solid $event-arrow;
						transform: rotate(-45deg);
						transition: 0.2s ease;
					}
				}

				/*IE 11 only*/
				.location-lbl,
				.close-lbl {
					top: 50%;
					transform: translateY(-50%);
				}
			}

			/*Warnings rows-start*/
			
			$num-row: 1;
			$num-order: 1;
			@while $num-row < $max-rows + 1 {

				.warn-row-#{$num-row} {
					order: $num-order;

					& + .warning-description {
						order: $num-order + 1;
						$num-order: $num-order + 2;
					}
				}
				$num-row: $num-row + 1;
			}
			/*Warnings rows-end*/

			.warning-description {
				display: none;
				opacity: 0;
				transition: 0.3s ease;
				flex-basis: 100%;
				//max-height: 180px;
				//overflow: hidden;
				margin: 0 2px 5px;
				padding: 20px;
				background: $element-background;
				border-radius: 4px;

				.left-description {
					width: 40%;
				}

				.left-description,
				.right-description {
					//flex-basis: 55%;
					padding: 0 12px 0 0;

					h2 {
						color: $font-color;
						font-weight: 500;
						font-size: 20px;
						padding-bottom: 20px;
						text-align: left;
						margin-top: 0;
					}

					p {
						font-weight: 600;
						color: $font-color;
						font-size: 1.4rem;

						span {
							//flex-basis: 75%;
							text-align: left;
							padding-left: 10px;
							font-weight: normal;
							color: $font-color;
							font-size: 1.4rem;
						}
					}

				}

				.left-description {
					h2 {
						max-width: 271px;
						text-overflow: ellipsis;
						overflow: hidden;
						@include respond-to(smallDesktop) {
							max-width: 100%;
						}
						@include respond-to(largePhone) {
							max-width: 600px;
						}
					}
				}

				.right-description-block {
					//flex-basis: 45%;
					border-left: thin solid $line-color;
					padding: 0 0 0 12px;
					width: 100%;

					.right-title {
						//width: 70%;
						margin: 0 0 0 auto;
						padding: 0 0 11px 0;
						background: $description-background;
						font-family: $rc-font-bold;
						font-weight: 600;
						font-size: 14px;
						text-align: left;
						color: #000;
					}

					.right-description {
						//flex-basis: 45%;
						max-height: 215px;
						overflow-y: auto;

						p {
							& span:nth-of-type(1) {
								font-size: 10px;
								//flex-basis: 30%;
								padding-left: 0;
							}

							& span:nth-of-type(2) {
								font-size: 12px;
								//flex-basis: 70%;
								padding-right: 10px;
								text-align: left;
								overflow-wrap: anywhere;
								white-space: pre-line;
								width: 100%;
							}
						}
					}
				}
			}

			//reveal class - for showing warning description
			.warning-label.reveal {
				background: $description-background;

				& > .close-lbl:after {
					top: 3px;
					transform: rotate(-225deg);
				}

				& + .warning-description {
					display: flex;
					background: $description-background;
					opacity: 1;
					@include rc-appear();
				}
			}
		}

		/*Country block*/
		.country-block {
			flex-basis: 100%;
			padding: 15px 10px 15px 30%;
			margin-top: 30px;
			font-size: 1.4rem;
			font-weight: 300;
			color: $font-color;

			& span {
				flex-basis: 30%;
			}
		}

		/*Events block*/
		.events-block {
			margin-bottom: 30px;

			& div:first-child {
				border-radius: 4px 4px 0 0;
			}

			& div:last-child {
				border-radius: 0 0 4px 4px;
			}
			& > div {
				position: relative;
				&:not(:last-of-type):after {
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					content: '';
					display: block;
					width: 94%;
					border-bottom: thin solid $block-separator;
				}
				.big-block,
				.single-block {
					display: block;
					position: relative;
					padding: 15px 10px;
					background: $element-background;
					text-align: left;
					border: 1px solid $element-background;
					box-sizing: border-box;
					font-size: 0;
	
					& > input[type=checkbox] {
						display: none;
	
						&:checked {
	
							& ~ label {
								color: $open-label;
	
								& > span {
									transition: 0.2s ease;
									transform: rotate(-180deg);
									&:before {
										opacity: 0;
									}
								}
							}
	
							& ~ .block-closed {
								display: none;
							}
	
							& ~ .block-opened {
								display: block;
								@include rc-appear();
							}
						}
					}
	
					label {
						font-family: $rc-font-normal;
						position: relative;
						padding-left: 25px;
						color: $font-color;
						font-size: 1.6rem;
						width: 30%;
						display: inline-flex;
						-webkit-tap-highlight-color: transparent;
	
						& > span {
							display: inline-block;
							width: 18px;
							height: 18px;
							border: 1px solid $block-status;
							border-radius: 50%;
							position: absolute;
							top: 0;
							left: 0;
							background: $element-background;
							transition: 0.2s ease;
							transform: rotate(180deg);
	
							&:after,
							&:before {
								content: '';
								display: block;
								position: absolute;
								width: 10px;
								height: 1px;
								background: $block-status;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
							}
	
							&:before {
								width: 1px;
								height: 10px;
								opacity: 1;
								transition: 0.2s ease;
							}
						}
	
					}
	
					.block-closed,
					.block-opened {
						display: inline-flex;
						justify-content: space-around;
						width: 70%;
	
						& > div {
							width: calc(100% / 3);
							display: flex;
							justify-content: center;
							-webkit-tap-highlight-color: transparent;
	
							& > a {
								font-size: 1.2rem;
							}
						}
					}
	
					.block-opened {
						display: none;
						width: 100%;
	
						.inner-block {
							display: flex;
							padding: 10px 0;
							width: 100%;
							box-sizing: border-box;
							font-size: 0;
	
							.inner-label {
								padding-left: 50px;
								font-family: $rc-font-normal;
								color: $font-color;
								font-size: 1.4rem;
								font-weight: 300;
								margin: 0;
								width: 30%;
								display: inline-flex;
								text-align: left;
							}
	
							.inner-warnings {
								justify-content: space-around;
								display: inline-flex;
								width: 70%;
	
								& > div {
									width: calc(100% / 3);
									font-size: 1.2rem;
									display: flex;
									justify-content: center;
								}
							}
						}
					}
				}
			}

			.big-block label {
				cursor: pointer;
				-webkit-tap-highlight-color: transparent;
			}

		}

	}

}

@include respond-to(smallDesktop) {

	header.rc-layout {
		padding: 22px 10px;

		.hero-container {
			.rc-layout-inner {
				padding: 0;
			}
		}
	}

	.dashboard-title-wrapper {
		.dashboard-title {

			.serv-label {
				margin: 0 auto;
			}

			.labels {
				display: none;
			}

			h1 {
				text-align: center;
			}

			.core-services-info {
				justify-content: center;
			}
		}
	}

	.dashboard-wrapper {
		justify-content: center;


		.services-tab {
			padding-top: 0;

			/*Warnings block*/
			.warning-block {

				.warning-label {
					flex-basis: 100%;
					margin: 0 0 6px;
					position: relative;
				}

				/*RESET Warnings rows-start*/
				$num-row: 1;
				@while $max-rows >  0 {

					.warn-row-#{$num-row} {
						order: inherit;

						& + .warning-description {
							order: inherit;
						}
						$num-row: $num-row + 1;
					}
					$max-rows: $max-rows - 1;
				}
				/*RESET Warnings rows-end*/

				.warning-description {
					flex-wrap: wrap;
					padding: 14px;
					max-height: 385px;
					flex-basis: 100%;

					.left-description {
						flex-basis: 100%;
						padding: 0 0 15px 0;
						position: relative;

						&:after {
							content: '';
							position: absolute;
							bottom: 10px;
							left: 0;
							width: 100%;
							border-bottom: thin solid $line-color;
						}

						p {
							span {
								flex-basis: 70%;
							}
						}
					}

					.right-description-block {
						flex-basis: 100%;
						border-left: none;

						.right-description {
							max-height: 160px;

							p {
								& span:nth-of-type(1) {
									flex-basis: 30%;
									padding-left: 0;
								}

								& span:nth-of-type(2) {
									flex-basis: 70%;
								}
							}
						}
					}
				}
			}

			/*Country block*/
			.country-block {
				padding-left: 0;
			}

			/*Events block*/
			.events-block {
				overflow: hidden;
				& > div {
					.big-block,
					.single-block {
						justify-content: center;
						width:  100%;
	
						& > label {
							padding-bottom: 10px;
							width: 100%;
							display: flex;
							justify-content: center;
							margin: 0 auto;
							padding-left: 0;
	
							& > span {
								position: relative;
								margin-right: 7px;
							}
						}
	
						.block-opened {
	
							.inner-block {
								flex-direction: column;
	
								.inner-label {
									width: 100%;
									justify-content: center;
									padding-left: 0;
									padding-bottom: 10px;
								}
	
								.inner-warnings {
									width: 100%;
								}
							}
						}
	
						.block-closed {
							width: 100%;
						}
					}
				}

				.single-block label {
					padding-left: 0;
				}
			}

		}

	}
}

.general-error {
	padding-top: 100px;
	text-align: left;

	h1 {
		font-size: 4.5rem;
		margin-left: 0;
	}
	p {
		font-size: 1.8rem;
	}

}

/*--------Popup---------*/
.popup {
	background: rgba(0, 0, 0, 0.71);
	.popup-container {
		background: #ffffff;
		padding: 40px 50px;
		width: 100%;
		max-width: 540px;
		.close {
			display: block;
			width: 40px;
			height: 40px;
			min-width: 21px;
			position: absolute;
			top: 20px;
			right: 15px;
			z-index: 10;
			outline: none;
			cursor: pointer;
			transition: all .2s ease;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
		
			&:before,
			&:after {
				display: block;
				content: '';
				width: 60%;
				height: 1px;
				background: #222;
				position: absolute;
				top: 50%;
				left: 50%;
			}
		
			&:before {
				transform: translate(-50%, -50%) rotate(45deg);
			}
		
			&:after {
				transform: translate(-50%, -50%) rotate(-45deg);
			}
		
			@include respond-to(largePhone) {
				top: 8px;
				right: 12px;
			}
		}
	}
}